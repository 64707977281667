import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./ServiceWorkflowBlock.module.css";

const ServiceWorkflowBlock = ({ block }) => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    adaptiveHeight: true,
  };

  let image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  const getItemLayout = (item, i, key) => {
    return <div className={`${s.item}`} key={`${key}-${i}`}>
      <div className={`${s.itemHead}`}>
        <span>{i + 1}</span>
        <h4>{item.title}</h4>
      </div>
      <div className={`${s.itemContent}`}>{!!item.content && parse(item.content)}</div>
    </div>
  };

  return (
    <>
      <section className={`${s.workflow}`}>
        <div className="container">
          <h2 className={`${s.title}`}>{!!block.title && parse(block.title)}</h2>
        </div>
        <div className={`container`}>
          <div className={`${s.info} row`}>
            <div className="col-md-6 px-0 d-none d-md-block">
              {!!image.data &&
                < GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={`${s.img} h-100`} />
              }
            </div>
            <div className="col-md-6 px-0">
              {!!block.items &&
                <>
                  <div className={`${s.items} d-none d-md-flex flex-column`}>
                    {block.items.map((item, i) => {
                      return getItemLayout(item, i, 'workflow-item');
                    })}
                  </div>
                  <div className={`${s.items} d-md-none`}>
                    <Slider {...sliderSettings} className="adaptive-height">
                      {block.items.map((item, i) => {
                        return getItemLayout(item, i, 'workflow-slider-item');
                      })}
                    </Slider>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceWorkflowBlock;
