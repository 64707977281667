import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServiceHeroBlock.module.css";

const ServiceHeroBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
  };

  let blockStyles = {};
  if (!!image.data) {
    blockStyles["backgroundImage"] = `url(${image.data.images.fallback.src}), linear-gradient(45deg, black, transparent)`;
  } else {
    blockStyles["backgroundImage"] = `linear-gradient(45deg, black, transparent)`;
  }

  return (
    <>
      <section className={`${s.serviceTop}`} style={blockStyles}>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              {!!block.title &&
                <h1>{parse(block.title)}</h1>
              }
            </div>
            <div className="col-md-5">
              <StaticImage
                src="./images/arrow.svg"
                alt="star"
              />
            </div>
          </div>
          <div className="row">
            {!!block.subtitle &&
              <p>{parse(block.subtitle)}</p>
            }
            {!!block.buttonPage &&
              <Link
                to={block.buttonPage.uri}
                className={`${s.serviceTopButton} text-decoration-none`}
              >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceHeroBlock;
