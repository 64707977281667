import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./ServiceOffersBlock.module.css";

const ServiceOffersBlock = ({ block }) => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '8px',
    adaptiveHeight: true,
  };

  const getItemLayout = (item, key) => {
    let image = {
      data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
      alt: item.icon?.altText || ``,
    };
    return <div className="col-md-4 text-center" key={key}>
      {!!image.data &&
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.data}
          alt={image.alt}
          className={`${s.img}`} />
      }
      <h4>{item.title}</h4>
      {!!item.content && parse(item.content)}
    </div>
  };

  return (
    <>
      <section className={`${s.seoWeOffer}`}>
        <div className="container">
          <div className={`${s.weOffer}`}>
            <p>{!!block.beforeTitle && parse(block.beforeTitle)}</p>
            <h2>{!!block.title && parse(block.title)}</h2>
            <p>{!!block.afterTitle && parse(block.afterTitle)}</p>
          </div>
        </div>
        <div className="container d-none d-md-block">
          <div className="row">
            {!!block.items &&
              block.items.map((item, i) => {
                return getItemLayout(item, `offers-item-${i}`);
              })
            }
          </div>
        </div>
        <div className="d-md-none">
          {!!block.items &&
            <Slider {...sliderSettings} className="adaptive-height">
              {block.items.map((item, i) => {
                return getItemLayout(item, `offers-slider-item-${i}`);
              })}
            </Slider>
          }
        </div>
      </section>
    </>
  );
};

export default ServiceOffersBlock;
