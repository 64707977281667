import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./ServiceImageTextBlock.module.css";

const ServiceImageTextBlock = ({ block }) => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '8px',
    adaptiveHeight: true,
  };

  const getItemLayout = (item, key) => {
    let image = {
      data: item.image?.localFile?.childImageSharp?.gatsbyImageData,
      alt: item.image?.altText || ``,
    };
    return <div className={`${s.textImageItem} row align-items-center d-flex`} key={key}>
      <div className={`${s.imageWrapper} col-md-6`}>
        {/* <img src="./img/boost1.png" alt="" /> */}
        {!!image.data &&
          <GatsbyImage
            loading="eager"
            placeholder="dominantColor"
            image={image.data}
            alt={image.alt}
            className={`${s.image}`} />
        }
      </div>
      <div className="col-md-6">
        <div className={`${s.seoBoostItemText}`}>
          <h3>{item.title}</h3>
          {!!item.content &&
            parse(item.content)
          }
        </div>
      </div>
    </div>
  };

  return (
    <>
      <section className={`${s.seoBoost}`}>
        <div className="container">
          <h2>{block.title}</h2>
          {!!block.items &&
            <>
              <div className="row d-none d-md-flex">
                <div className={`${s.textImagesWrapper} col-xxl-10 mx-auto`}>
                  {block.items.map((item, i) => {
                    return getItemLayout(item, `imageText-item-${i}`);
                  })}
                </div>
              </div>
              <div className={`${s.textImagesWrapper} d-flex d-md-none flex-column service-carousel`}>
                <Slider {...sliderSettings} className="adaptive-height">
                  {block.items.map((item, i) => {
                    return getItemLayout(item, `imageText-slider-item-${i}`);
                  })}
                </Slider>
              </div>
            </>
          }
        </div>
      </section>
    </>
  );
};

export default ServiceImageTextBlock;
