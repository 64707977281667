import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServiceDataItem.module.css";

const ServiceDataItem = ({ block, active, style }) => {

  const logo = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  return (
    <>
      <div className={`${s.ourServiceItem} ${!active ? '' : s.active}`} style={style}>
        <div className={`${s.serviceItemBlock} d-flex flex-column align-items-center`}>
          <h3>{block.title}</h3>
          <div className={`${s.serviceIconBlock}`}>
            {!!logo.data &&
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={logo.data}
                alt={logo.alt}
                width={58}
                height={58}
                className={`${s.serviceIconImage}`} />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDataItem;
