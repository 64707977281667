import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./ServiceRelatedPostsBlock.module.css";
import Post from './../../post/post';

const ServiceRelatedPostsBlock = ({ block }) => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: '8px',
  };

  return (
    <>
      <section className={`${s.seoSeoResources}`}>
        <div className="container">
          <div className="row">
            <h2>{block.title}</h2>
          </div>
        </div>
        <div className="container d-none d-md-block">
          <div className="row">
            {!!block.posts &&
              block.posts.map((item, i) => {
                return <div className="col-md-4" key={`related-post-${i}`}>
                  <Post post={item.post} />
                </div>;
              })
            }
          </div>
        </div>
        <div className="d-md-none">
          {!!block.posts &&
            <Slider {...sliderSettings}>
              {block.posts.map((item, i) => {
                return <Post post={item.post} key={`related-slider-post-${i}`} />;
              })}
            </Slider>
          }
        </div>
      </section>
    </>
  );
};

export default ServiceRelatedPostsBlock;
