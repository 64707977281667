import { graphql } from "gatsby"
import React from "react"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css"
import "../assets/css/@wordpress/block-library/build-style/theme.css"

import Faq from './../components/faq/faq'
import GlobalFormBlock from './../components/GlobalFormBlock/GlobalFormBlock'
import GlobalServicesBlock from './../components/GlobalServicesBlock/GlobalServicesBlock'
import GlobalTeamBlock from './../components/GlobalTeamBlock/GlobalTeamBlock'
import HomeTestimonialsBlock from './../components/HomeTestimonialBlock/HomeTestimonialsBlock'
import Layout from "./../components/layout"
import Seo from "./../components/seo"
import ServiceBannerBlock from './../components/single-service-components/ServiceBannerBlock/ServiceBannerBlock'
import ServiceDataItemsBlock from './../components/single-service-components/ServiceDataItemsBlock/ServiceDataItemsBlock'
import ServiceHeroBlock from './../components/single-service-components/ServiceHeroBlock/ServiceHeroBlock'
import ServiceImageTextBlock from './../components/single-service-components/ServiceImageTextBlock/ServiceImageTextBlock'
import ServiceOffersBlock from './../components/single-service-components/ServiceOffersBlock/ServiceOffersBlock'
import ServiceRelatedPostsBlock from './../components/single-service-components/ServiceRelatedPostsBlock/ServiceRelatedPostsBlock'
import ServiceWorkflowBlock from './../components/single-service-components/ServiceWorkflowBlock/ServiceWorkflowBlock'
import TeamMediaBlock from './../components/TeamMediaBlock/TeamMediaBlock'

const ServiceTemplate = ({ data: { post }, location }) => {
  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.excerpt}
        pageSeo={post}
        location={location}
      />

      {!!post.acf_services?.topBlock?.image &&
        <ServiceHeroBlock block={post.acf_services?.topBlock} />
      }

      {!!post.acf_services?.textImagesBlock?.items?.length &&
        <ServiceImageTextBlock block={post.acf_services?.textImagesBlock} />
      }

      {!!post.acf_services?.offersBlock?.items?.length &&
        <ServiceOffersBlock block={post.acf_services?.offersBlock} />
      }

      {!!post.acf_services?.testimonialsBlock?.testimonials?.length &&
        <HomeTestimonialsBlock block={post.acf_services?.testimonialsBlock} />
      }

      {!!post.acf_services?.videoBlock?.youtubeVideoId &&
        <TeamMediaBlock block={{
          ...post.acf_services?.videoBlock,
          type: 'video'
        }} />
      }

      {!!post.acf_services?.dataItems?.items?.length &&
        <ServiceDataItemsBlock block={post.acf_services?.dataItems} />
      }

      {!!post.acf_services?.workflowBlock?.items?.length &&
        <ServiceWorkflowBlock block={post.acf_services?.workflowBlock} />
      }

      {!!post.acf_services?.relatedServices?.services?.length &&
        <GlobalServicesBlock block={post.acf_services?.relatedServices} />
      }

      {!!post.acf_services?.teamBlock?.teammates?.length &&
        <GlobalTeamBlock block={post.acf_services?.teamBlock} />
      }

      {!!post.acf_services?.bannerBlock?.buttonPage &&
        <ServiceBannerBlock block={post.acf_services?.bannerBlock} />
      }

      {!!post.acf_services?.relatedPostsBlock?.posts?.length &&
        <ServiceRelatedPostsBlock block={post.acf_services?.relatedPostsBlock} />
      }

      {!!post.acf_services?.faqBlock?.length &&
        <Faq block={{ items: post.acf_services?.faqBlock }} />
      }

      <GlobalFormBlock block={post.acf_services?.formBlock} pageTitle={post.title} />

    </Layout>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceById(
    $id: String!
  ) {
    post: wpService(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acf_services {
        bannerBlock {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          buttonPage {
            ... on WpPage {
              uri
              title
            }
          }
          buttonText
        }
        cardBlock {
          color
          description
          fieldGroupName
          title
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          hoverLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        faqBlock {
          question
          answer
        }
        formBlock {
          title
          subtitle
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        offersBlock {
          afterTitle
          beforeTitle
          fieldGroupName
          title
          items {
            content
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        relatedServices {
          title
          subtitle
          services {
            fieldGroupName
            service {
              __typename
              ... on WpService {
                databaseId
                uri
                title
                excerpt
                acf_services {
                  cardBlock {
                    color
                    description
                    title
                    logo {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                      }
                      altText
                    }
                    hoverLogo {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                      }
                      altText
                    }
                  }
                }
                featuredImage {
                  node {
                    __typename
                    ... on WpMediaItem {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        dataItems {
          title
          subtitle
          itemsBackgroundColor
          itemsTitleColor
          items {
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        relatedPostsBlock {
          title
          posts {
            post {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    gatsbyImage(width: 600,quality: 80)
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
        teamBlock {
          title
          teammates {
            teammate {
              ... on WpTeammate {
                title
                acf_teammate {
                  goal
                  position
                  title
                  buttonPage {
                    ... on WpPage {
                      uri
                      title
                    }
                  }
                  buttonText
                }
                content
                featuredImage {
                  node {
                    __typename
                    ... on WpMediaItem {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        testimonialsBlock {
          title
          testimonials {
            testimonial {
              __typename
              ... on WpTestimonial {
                acf_testimonial {
                  position
                  stars
                }
                content
                title
                featuredImage {
                  node {
                    __typename
                    ... on WpMediaItem {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        textImagesBlock {
          title
          items {
            content
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        topBlock {
          title
          subtitle
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          buttonPage {
            ... on WpPage {
              uri
              title
            }
          }
          buttonText
        }
        videoBlock {
          youtubeVideoId
          title
          afterTitle
        }
        workflowBlock {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          items {
            content
            title
          }
        }
      }
    }
  }
`
