import React from "react";
import ServiceDataItem from "./ServiceDataItem";
import * as s from "./ServiceDataItemsBlock.module.css";

const ServiceDataItemsBlock = ({ block }) => {
  let itemsStyles = {};
  if (!!block.itemsBackgroundColor) {
    itemsStyles['--bg-color'] = block.itemsBackgroundColor;
    // itemsStyles['--shadow-color-1'] = block.itemsBackgroundColor + '0a';
    // itemsStyles['--shadow-color-2'] = block.itemsBackgroundColor + '1f';
  }
  if (!!block.itemsTitleColor) {
    itemsStyles['--title-color'] = block.itemsTitleColor;
    // itemsStyles['--shadow-color-1'] = block.itemsTitleColor + '0a';
    // itemsStyles['--shadow-color-2'] = block.itemsTitleColor + '1f';
  }
  return (
    <>
      <section className={`${s.ourServices}`}>
        <div className="container">
          <h2 className="text-center">{block.title}</h2>
          <h5 className="text-center">{block.subtitle}</h5>
          <div className={`${s.servicesBlock} d-flex flex-wrap justify-content-center`} style={{gap: '30px'}}>
            {(!!block.items && block.items.length > 0) &&
              block.items.map((item, i) =>
                <ServiceDataItem block={item} active={false} key={`service-item-${i}`} style={itemsStyles}/>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDataItemsBlock;
