import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as s from "./ServiceBannerBlock.module.css";

const ServiceBannerBlock = ({ block }) => {
  let image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  return (
    <>
      <section className={`${s.banner}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 order-2 order-md-1">
              <h2>{block.title}</h2>
              {!!block.buttonPage &&
                <Link to={block.buttonPage.uri} className={`${s.btn} text-decoration-none`}>
                  {!block.buttonText ? block.buttonPage.title : block.buttonText}
                </Link>
              }
            </div>
            <div className="col-md-5 order-1 order-md-2">
              {!!image.data &&
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={`${s.image}`} />
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceBannerBlock;
